import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

import { ProgressBar } from "../progress-bar/progress-bar";

export interface ProgressBarWithTitleAndValueProps {
  /** descriptive title to be shown on top left */
  title?: string;
  /** descriptive subtitle to be shown below title */
  subTitle?: string;
  /** current progress value (0-100) */
  progress: number;
  /** actual value */
  actualValue?: number | string;
  /** current color */
  progressColor?: string;
  /** (optional) bar height */
  progressBarHeight?: string;
  /** (optional) text Color, default black */
  textColor?: string;
  /** (optional) text Bold, default 400 */
  textWeight?: number;
  /** (optional) margin for further reusability */
  customMargin?: string;
  /** (optional) padding for further reusability */
  customPadding?: string;
  backgroundColor?: string;
  centerActualValue?: boolean;
  /** (optional) align actual value to right of title */
  alignValueRight?: boolean;
}

export const ProgressBarWithTitleAndValue: FC<
  ProgressBarWithTitleAndValueProps
> = ({
  title,
  subTitle,
  actualValue,
  progress,
  progressColor,
  progressBarHeight,
  textColor,
  textWeight,
  customMargin = "40px 20px",
  customPadding = "0px",
  backgroundColor = "#EEEEEE",
  alignValueRight = false,
}) => {
  return (
    <Box
      data-testid="progress-bar-title-value"
      sx={{
        margin: customMargin,
        padding: customPadding,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ...(alignValueRight
            ? {
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "15px",
              }
            : {
                gap: "10px",
                marginBottom: "15px",
              }),
        }}
      >
        {title && (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              lineHeight: "1.2rem",
              fontSize: "1rem",
              color: textColor,
              fontWeight: textWeight,
              m: 0,
            }}
          >
            {title}
          </Typography>
        )}

        {subTitle && !alignValueRight && (
          <Typography
            sx={{
              lineHeight: "1.2rem",
              fontSize: "0.9rem",
              color: textColor,
              m: 0,
            }}
          >
            {subTitle}
          </Typography>
        )}

        {actualValue && alignValueRight && (
          <Typography
            sx={{
              lineHeight: "1.2rem",
              fontSize: "1rem",
              color: textColor,
              fontWeight: textWeight,
              m: 0,
            }}
          >
            {actualValue}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            mr: "12px",
          }}
        >
          <ProgressBar
            progressBarHeight={progressBarHeight}
            progress={progress}
            progressColor={progressColor}
            backgroundColor={backgroundColor}
          />
        </Box>

        {actualValue && !alignValueRight && (
          <Typography
            sx={{
              whiteSpace: "nowrap",
              lineHeight: "1.2rem",
              fontSize: "1rem",
              color: textColor,
              fontWeight: textWeight,
              textAlign: "right",
            }}
          >
            {actualValue}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
